//Import Icon scss
@import "../scss/icons.scss";

//Import Dark theme
@import "../scss/bootstrap-dark.scss";
@import "../scss/app-dark.scss";

//import Light Theme
// @import "../scss/bootstrap.scss";
// @import "../scss/app.scss";

// RTL mode
// @import "../scss/custom/rtl/bootstrap-rtl";
// @import "../scss/custom/rtl/components-rtl";
// @import "../scss/custom/rtl/float-rtl";
// @import "../scss/custom/rtl/general-rtl";
// @import "../scss/custom/rtl/pages-rtl";
// @import "../scss/custom/rtl/plugins-rtl";
// @import "../scss/custom/rtl/spacing-rtl";
// @import "../scss/custom/rtl/structure-rtl";
// @import "../scss/custom/rtl/text-rtl";

body {
  * {
    outline: none;
  }
}

.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 0px;
  margin: 2em 0;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

//modal close icon
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

// kanban board
.react-kanban-column {
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.react-kanban-card-adder-button {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  transition: all 0.3s ease 0s;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.react-kanban-card-adder-form {
  box-sizing: border-box;
  max-width: 250px;
  min-width: 250px;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin-bottom: 7px;
}

// calendar
.fc-daygrid-event {
  color: $white !important;
}
.card-columns .card {
  margin-bottom: 24px;
}

@media (min-width: 576px){
  .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
      grid-column-gap: 1.25rem;
      -webkit-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
}
}
.react-bootstrap-table-pagination{
  --bs-gutter-x: 0;
}